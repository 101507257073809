/* App.css */

.app-container {
  font-family: 'Arial', sans-serif;
}

.navbar {
  background: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.logo {
  max-width: 200px;
  margin-bottom: 20px;
}

.nav-link {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  font-size: 18px;
}

.nav-link:hover {
  text-decoration: underline;
}

.container {
  margin: 20px;
}